<template>
    <h1>Page Not Found</h1>
</template>

<script>
// export default {
//     created() {
//         this.$router.push({
//             name: 'NotFound',
//             params: { pathMatch: this.$route.path.substring(1).split('/') },
//             query: this.$route.query,
//             hash: this.$route.hash,
//         })
//     },
// }
</script>